/**
 * leerPayload devuelve el payload del token en json
 * @return {Object} payload en JSON
 */
function leerPayload () {
  const token = localStorage.getItem('apollo-token')
  const payload = token ? token.split('.')[1] : null
  return payload ? JSON.parse(atob(payload)) : {}
}

/**
 * leerPayload devuelve el id del token en json
 * @return {String} el identificador de la persona loggueada
 */
function leerPersonaId () {
  return leerPayload().id
}

/**
 * leerPayload devuelve el nombre del token en json
 * @return {String} el nombre completo de la persona loggueada
 */
function leerPersonaNombre () {
  return leerPayload().name
}

/**
 * leerPayload devuelve los roles del token en json
 * @return {String} el rol de la persona loggueada
 */
function leerRoles () {
  return leerPayload().role
}

/**
 * leerPayload devuelve el tiempo de expiración del token en json
 * @return {String} el fecha de expiracion de la persona loggueada
 */
function leerExpiracion () {
  return leerPayload().exp
}

export {
  leerPersonaId,
  leerPersonaNombre,
  leerRoles,
  leerExpiracion
}
