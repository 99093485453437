// eslint-disable
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import {
  createApolloClient,
  restartWebsockets
} from 'vue-cli-plugin-apollo/graphql-client'
import { onError } from 'apollo-link-error'
import { fromPromise, ApolloLink } from 'apollo-link'
import { leerPersonaId } from '@/utils/datosToken.js'
import router from '@/router/index'
import renovarTokenGql from '@/graphql/renovarToken.gql'
// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token'

// Http endpoint
const httpEndpoint =
  process.env.VUE_APP_GRAPHQL_HTTP || 'http://0.0.0.0:4001/graphql'

// Errors for refreshToken
const ERROR_TOKEN = {
  tokenExpired: 'Your token is expired',
  autenticacionRequerida: 'autenticacion_requerida'
}

const getNewToken = async () => {
  const personaId = leerPersonaId()
  const { data } = await apolloClient.query({ query: renovarTokenGql, variables: { personaId, empresa: '' } })
  return data?.renovarToken?.token
}

const errorLink = onError(
  ({ graphQLErrors, /*  networkError,  */operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err.message) {
          case ERROR_TOKEN.tokenExpired:
          case ERROR_TOKEN.autenticacionRequerida:
            return fromPromise(
              getNewToken().catch((error) => {
                // clean token of LS and redirect to login
                localStorage.removeItem(AUTH_TOKEN)
                router.push({
                  name: 'Login'
                })
                console.log(error)
                // eslint-disable-next-line
                return
              })
            )
              .filter((value) => Boolean(value))
              .flatMap((accessToken) => {
                // modify the token with new accessToken
                localStorage.setItem(AUTH_TOKEN, accessToken)
                operation.setContext({
                  headers: {
                    authorization: `Bearer ${accessToken}`
                  }
                })

                // retry the request, returning the new observable
                return forward(operation)
              })
        }
      }
    }
  }
)

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: ApolloLink.from([
    errorLink
    // httpLink,
  ])

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

// Create apollo client
const { apolloClient, wsClient } = createApolloClient({
  ...defaultOptions
})
// Call this in the Vue app file
export function createProvider () {
  apolloClient.wsClient = wsClient

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      }
    },
    errorHandler (error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message
      )
    }
  })

  return apolloProvider
}

// Manually call this when user log in
export async function onLogin (apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout (apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}
export async function isAuthenticated (to, from, next) {
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem(AUTH_TOKEN) ? next() : next('/login/')
  }
}
export async function isNotAuthenticated (to, from, next) {
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem(AUTH_TOKEN) ? next('/') : next()
  }
}
