<template>
  <nav :class="['menu-contenedor', {'expandido': mostarMenu }]">
    <div class="menu-encabezado">
      <header @click="cerrarMenu(enlaces[0].ruta)">
        Global Trackit
        <span class="fs-inherit d-block">System</span>
      </header>
      <button
        :class="[mostarMenu ? 'btn-flat' : 'sale-btn btn-primario',
          'btn ripple-parent m-1 px-3 py-3 d-lg-none']"
        @click="mostarMenu = !mostarMenu"
      >
        <font-awesome-icon icon="bars" size="lg" />
      </button>
    </div>
    <ul class="menu-lista">
      <li
        v-for="enlace in enlaces"
        :key="enlace.nombre"
        :class="['menu-enlace',
          {'activo': verificarVistaActiva(enlace.ruta)},
          {'disabled': enlace.deshabilitar}]"
        @click="cerrarMenu(enlace.ruta)"
      >
        <font-awesome-icon
          :icon="[enlace.icono == 'trello' ? 'fab' : 'fas', enlace.icono]"
          class="mx-2"
        />
        {{ enlace.nombre }}
      </li>
    </ul>
    <div class="informacion-adicional">
      <span class="nombre-usuario">
        <i class="fas fa-user-circle"></i>
        {{ usuario && usuario.nombre ? usuario.nombre : '' }}
        <small class="d-block">
          {{ usuario && usuario.usuario ? usuario.usuario : '' }}
        </small>
      </span>
      <small class="d-block">
        ©
        <span class="font-weight-bold fs-inherit letter-spacing-1">
          Global Trackit System
        </span>
      </small>
      <small class="d-block mt-n1">
        Versión
        {{ fechaCompilacion() }}
      </small>
    </div>
  </nav>
</template>

<script>
import { onLogout } from '../vue-apollo'
import { leerPersonaNombre, leerPersonaId } from '@/utils/datosToken.js'
import clienteGql from '@/graphql/cliente.gql'

export default {
  name: 'Navbar',
  data () {
    return {
      enlaces: [
        {
          nombre: 'Paquetes',
          ruta: '/paquetes', // en wireframe ruta: /
          icono: 'boxes',
          deshabilitar: false
        },
        {
          nombre: 'Órdenes',
          ruta: '/compras',
          icono: 'trello',
          deshabilitar: false
        },
        {
          nombre: 'Direcciones',
          ruta: '/direcciones',
          icono: 'route',
          deshabilitar: false
        },
        {
          nombre: 'Pagos',
          ruta: '/pagos',
          icono: 'file-invoice-dollar',
          deshabilitar: false
        },
        {
          nombre: 'Configuración',
          ruta: '/configuracion',
          icono: 'cogs',
          deshabilitar: false
        },
        {
          nombre: 'Comunicarme',
          ruta: '/comunicarme',
          icono: 'bell',
          deshabilitar: false
        },
        {
          nombre: 'Salir',
          ruta: '/login',
          icono: 'sign-out-alt',
          deshabilitar: false
        }
      ],
      mostarMenu: false,
      // Usuario
      leerPersonaNombre,
      usuarioId: ''
    }
  },
  methods: {
    cerrarMenu (ruta) {
      if (this.mostarMenu) this.mostarMenu = false
      if (ruta === '/login') this.logout()
      else if (this.$route.path !== ruta) this.$router.push(ruta)
    },

    fechaCompilacion () {
      const metaArray = document.head.querySelector('[name=updated]').content
        .split(' ')
      let mes
      switch (metaArray[1]) {
        case 'Jan':
          mes = '01'
          break
        case 'Feb':
          mes = '02'
          break
        case 'Mar':
          mes = '03'
          break
        case 'Apr':
          mes = '04'
          break
        case 'May':
          mes = '05'
          break
        case 'Jun':
          mes = '06'
          break
        case 'Jul':
          mes = '07'
          break
        case 'Aug':
          mes = '08'
          break
        case 'Sep':
          mes = '09'
          break
        case 'Oct':
          mes = '10'
          break
        case 'Nov':
          mes = '11'
          break
        default:
          mes = '12'
          break
      }
      return [metaArray[2], mes, metaArray[3]].join('/') +
        ' ' + metaArray[4].substring(0, 5)
    },

    logout () {
      onLogout(this.$apolloProvider.defaultClient)
      this.$emit('reestablecer-colores')
      this.$router.push('/login')
    },

    verificarVistaActiva (ruta) {
      if (this.$route.path === ruta) return true // Si es la misma ruta
      else if ((this.$route.path.slice(1).split('/')[0] === ruta.slice(1).split('/')[0]) &&
        (this.$route.path.slice(1).split('/')[0] !== '')) return true // Si comparten ruta y es distinta de '/'
      else return false
    }
  },
  watch: {
    usuario: function () {
      if (this.usuario) {
        this.$emit('cambiar-colores', this.usuario.agencia.coloresEmpresariales)
      }
    }
  },
  mounted () {
    this.usuarioId = leerPersonaId()
  },
  apollo: {
    usuario () {
      return {
        query: clienteGql,
        variables () {
          return {
            filter: {
              id: leerPersonaId()
            }
          }
        },
        update: (data) => data.Cliente[0],
        fetchPolicy: 'cache-and-network',
        skip () {
          return !this.usuarioId
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .menu-contenedor {
    background-color: var(--fondo-barra); /* $terciario + blanco */
    box-shadow: 0 0 5px rgb(4, 4, 13, 0.3); /* $oscuro + opacidad */
    position: fixed;
    transition: transform .5s ease;
    width: 230px;
    z-index: 10;

    @media screen and (max-width: 991px) {transform: translateX(-100%);}

    &.expandido {
      @media screen and (max-width: 991px) {transform: translateX(0);}
    }

    .menu-encabezado {
      align-items: center;
      background-color: var(--terciario, $terciario);
      border-radius: 0 0 7px 0;
      color: var(--texto-encabezado-barra);
      display: flex;
      flex-flow: row nowrap;
      padding: .75rem 0;
      position: relative;

      &::before {
        background: var(--terciario, $terciario);
        border-radius: 0 0 0 50px;
        bottom: -16px;
        content: '';
        height: 34px;
        left: -1px;
        position: absolute;
        transform: rotate(-8deg);
        width: 97%;
        z-index: 0;
      }

      header {
        cursor: pointer;
        font-size: 1.2rem;
        font-weight: 500;
        position: relative;
        text-align: center;
        user-select: none;
        width: 100%;
        z-index: 1;
      }

      button {
        position: absolute;
        right: -4px;
        transition: right .5s ease;
        z-index: 2;
      }
      button.sale-btn {right: -62px;}
    }

    .menu-lista {
      height: calc(100vh - 80px - 83px); /* - encabezado - fechaCompilacion container */
      list-style-type: none;
      margin-bottom: 0;
      overflow-y: auto;
      padding: 1.75rem 0 1rem;
    }

    .menu-lista .menu-enlace {
      color: var(--texto-barra);
      cursor: pointer;
      margin: .25rem .75rem;
      padding: .4rem .5rem;
      transition: color .5s ease, font-weight .5s ease;
      user-select: none;

      &.activo {
        color: var(--primario, $primario);
        font-weight: 700;
      }

      &.disabled {color: #757575;}

      svg {width: 20px !important;}
    }

    .informacion-adicional {
      color: var(--texto-informativo);
      padding: .5rem 0;
      text-align: center;
      user-select: none;
    }

    .nombre-usuario {
      display: block;
      font-size: .8rem;
      line-height: 1.3;
      margin-bottom: .25rem;
    }
  }
</style>
