<template>
  <div v-if="!cargandoApp" id="app">
    <Navbar
      v-if="!ocultarNavbar"
      @cambiar-colores="cambiarColor($event)"
      @reestablecer-colores="reestablecerColores()"
    />
    <div id="contenido" :class="{'ocultar-navbar': ocultarNavbar}">
      <transition
        mode="out-in"
        :name="ocultarNavbar ? 'login-app-transicion' : 'vistas-app-transicion'"
      >
        <router-view/>
      </transition>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
export default {
  name: 'App',
  components: {
    Navbar
  },
  data () {
    return {
      cargandoApp: false,

      // Colores de la agencia
      colores: {
        primario: '',
        primarioTinte: '',
        primarioBtnTexto: '',

        terciario: '',
        terciarioTinte: '',
        terciarioBtnTexto: '',

        fondoBarra: '',
        textoEncabezadoBarra: '',
        textoBarra: '',
        textoInformativo: ''
      }
    }
  },
  computed: {
    ocultarNavbar () {
      const ruta = this.$route.path.slice(1).split('/')[0]
      return ruta === 'login' || ruta === 'activar-cliente' || ruta === 'recuperar-clave'
    }
  },
  methods: {
    cambiarColor (coloresEmpresariales) {
      if (coloresEmpresariales !== null) {
        this.colores = JSON.parse(JSON.stringify(coloresEmpresariales))
      } else {
        this.colores = {
          primario: '#FCC626',
          primarioTinte: '#FDD45C',
          primarioBtnTexto: '#071A6A',
          terciario: '#071A6A',
          terciarioTinte: '#26377D',
          terciarioBtnTexto: '#FFFFFF',
          textoEncabezadoBarra: '#FFFFFF',
          textoBarra: '#212427',
          fondoBarra: '#F6F6F9',
          textoInformativo: '#6c757d'
        }
      }
      this.establecerColores()
    },

    establecerColores () {
      const root = document.querySelector(':root')

      root.style.setProperty('--primario', this.colores.primario)
      root.style.setProperty('--primario-tinte', this.colores.primarioTinte)
      root.style.setProperty('--primario-btn-texto', this.colores.primarioBtnTexto)

      root.style.setProperty('--terciario', this.colores.terciario)
      root.style.setProperty('--terciario-tinte', this.colores.terciarioTinte)
      root.style.setProperty('--terciario-btn-texto', this.colores.terciarioBtnTexto)

      root.style.setProperty('--fondo-barra', this.colores.fondoBarra)
      root.style.setProperty('--texto-encabezado-barra', this.colores.textoEncabezadoBarra)
      root.style.setProperty('--texto-barra', this.colores.textoBarra)
      root.style.setProperty('--texto-informativo', this.colores.textoInformativo)
    },

    reestablecerColores () {
      this.colores = {
        primario: '#FCC626',
        primarioTinte: '#FDD45C',
        primarioBtnTexto: '#071A6A',
        terciario: '#071A6A',
        terciarioTinte: '#26377D',
        terciarioBtnTexto: '#FFFFFF',
        textoEncabezadoBarra: '#FFFFFF',
        textoBarra: '#212427',
        fondoBarra: '#F6F6F9',
        textoInformativo: '#6c757d'
      }
      this.establecerColores()
    }
  },
  created () {
    this.cargandoApp = true
    setTimeout(() => { this.cargandoApp = false }, 250)
  }
}
</script>

<style lang="scss" scoped>
  #contenido:not(.ocultar-navbar) {
    padding: 1.25rem 1rem 1rem;

    @media screen and (min-width: 992px) {
      margin-left: 230px;
      width: calc(100% - 230px); /* - ancho del navbar */
    }
  }

  // Estilos de transición de entrada entre vistas y login
  .vistas-app-transicion-enter-active,
  .login-app-transicion-enter-active {animation-name: fadeIn;}

  // Estilos de transición de salida entre vistas
  .login-app-transicion-leave-active,
  .vistas-app-transicion-leave-active {animation-name: fadeOut;}

  //Duración de las transiciones de Entrada
  .vistas-app-transicion-enter-active,
  .login-app-transicion-enter-active {animation-duration: .5s;}

  //Duración de las transiciones de Salida
  .vistas-app-transicion-leave-active,
  .login-app-transicion-leave-active {animation-duration: .25s;}
</style>
