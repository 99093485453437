import Vue from 'vue'
import VueRouter from 'vue-router'
import { isAuthenticated, isNotAuthenticated } from '../vue-apollo'
Vue.use(VueRouter)
const pushPrototype = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return pushPrototype.call(this, location).catch((e) => e)
}

const routes = [
  {
    path: '/login/:codigoAgencia?',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    beforeEnter: isNotAuthenticated,
    meta: { title: 'Login cliente | Global Trackit' }
  },
  {
    path: '/activar-cliente/:usuario',
    name: 'Activar',
    component: () => import('../views/Activar.vue'),
    beforeEnter: isNotAuthenticated,
    meta: { title: 'Activar cuenta | Global Trackit' }
  },
  {
    path: '/recuperar-clave/:usuario',
    name: 'Recuperar',
    component: () => import('../views/Recuperar.vue'),
    beforeEnter: isNotAuthenticated,
    meta: { title: 'Recuperar clave | Global Trackit' }
  },
  {
    path: '/',
    redirect: { name: 'Paquetes' }
  },
  {
    path: '/paquetes',
    name: 'Paquetes',
    component: () => import('../views/Paquete.vue'),
    meta: { title: 'Paquetes | Global Trackit' },
    beforeEnter: isAuthenticated,
    children: [
      {
        path: ':paqueteId',
        name: 'Paquete',
        component: () => import('../views/PaqueteId.vue'),
        meta: { title: 'Paquete | Global Trackit' }
      }
    ]
  },
  {
    path: '/pagos',
    name: 'Pagos',
    component: () => import('../views/Pagos.vue'),
    meta: { title: 'Pagos | Global Trackit' }
  },
  {
    path: '/configuracion',
    name: 'Configuracion',
    component: () => import('../views/Configuracion.vue'),
    meta: { title: 'Configuración | Global Trackit' },
    beforeEnter: isAuthenticated
  },
  {
    path: '/direcciones',
    name: 'Direcciones',
    component: () => import('../views/Direcciones.vue'),
    meta: { title: 'Direcciones | Global Trackit' },
    beforeEnter: isAuthenticated
  },
  {
    path: '/compras',
    name: 'Compras',
    component: () => import('../views/Orden.vue'),
    meta: { title: 'Compras | Global Trackit' },
    beforeEnter: isAuthenticated
  },
  {
    path: '/comunicarme',
    name: 'Comunicarme',
    component: () => import('../views/Comunicarme.vue'),
    meta: { title: 'Comunicarme | Global Trackit' },
    beforeEnter: isAuthenticated
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const defaultTitle = 'Global Trackit System'
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || defaultTitle
  })
})

export default router
