import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBars, faBoxes, faRoute, faBell, faShip, faFileInvoiceDollar, faCogs, faPlaneDeparture,
  faSignOutAlt, faSignInAlt, faEnvelope, faEnvelopeSquare, faLock, faPeopleArrows, faUpload,
  faPhoneAlt, faCheckCircle, faTimesCircle, faInfoCircle, faCalendarDay, faMapSigns, faEdit,
  faExclamationCircle, faExclamationTriangle, faMinusCircle, faFolderOpen, faImage, faImages,
  faBox, faClock, faPlusCircle, faSms, faBoxOpen, faUserCircle, faCircleNotch
} from '@fortawesome/free-solid-svg-icons'
import {} from '@fortawesome/free-regular-svg-icons'
import { faFacebook, faInstagram, faTrello, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

library.add(
  // Solid
  faBars, faBoxes, faRoute, faBell, faShip, faFileInvoiceDollar, faCogs, faPlaneDeparture,
  faSignOutAlt, faSignInAlt, faEnvelope, faEnvelopeSquare, faLock, faPeopleArrows, faUpload,
  faPhoneAlt, faCheckCircle, faTimesCircle, faInfoCircle, faCalendarDay, faMapSigns, faEdit,
  faExclamationCircle, faExclamationTriangle, faMinusCircle, faFolderOpen, faImage, faImages,
  faBox, faClock, faPlusCircle, faSms, faBoxOpen, faUserCircle, faCircleNotch,
  // Brand
  faFacebook, faInstagram, faTrello, faWhatsapp
)
